<template>
  <div class="vuse-content-wrapper training-performance">
    <v-row no-gutters justify="space-between" align="center">
      <smart-breadcrumbs :items="navigationElements" />
      <!-- <help class="help--breadcrumb" :helpId="" /> -->
    </v-row>
    <v-card elevation="2">
      <v-toolbar dense color="primary" dark flat>
        <v-toolbar-title class="text-h6">Desempeño de la red</v-toolbar-title>
      </v-toolbar>

      <!-- COMPANIES TREEVIEW -->
      <v-row class="mx-0">
        <v-col cols="12" md="4">
          <v-skeleton-loader
            type="list-item"
            v-if="loadingCompanies"
            class="d-flex align-center px-4"
          >
            <v-skeleton-loader type="avatar" class="skeleton__avatar--x-mall mr-1" />
            <v-skeleton-loader type="avatar" />
            <v-skeleton-loader type="chip" />
            <v-skeleton-loader type="table-cell" class="ml-2" />
          </v-skeleton-loader>
          <v-treeview
            color="primary"
            v-else
            dense
            open-on-click
            :items="companies"
            item-children="children"
            item-key="id"
          >
            <template v-slot:prepend="{ item, open }">
              <div class="float-left">
                <v-icon v-if="item.level_id === 1">
                  {{ open ? 'mdi-city-variant-outline' : 'mdi-city-variant' }}
                </v-icon>
                <v-icon v-if="item.level_id === 2">
                  {{ open ? 'mdi-office-building-outline' : 'mdi-office-building' }}
                </v-icon>

                <v-icon v-if="item.level_id === 3"> mdi-store </v-icon>

                <v-chip small v-if="item.level_id === 1" class="pa-2"
                  >Central{{ item.countries[0] ? `- ${item.countries[0].name}` : '' }}
                </v-chip>
                <v-chip small v-else-if="item.level_id === 2" class="pa-2">Master</v-chip>
              </div>
            </template>
            <template v-slot:label="{ item }">
              <div class="label-container" @click="openCompanyTree(item)">
                <span class="label">
                  {{ item.name }}
                </span>
              </div>
            </template>
          </v-treeview>
        </v-col>
        <v-col cols="12" md="8">
          <v-data-table
            dense
            :key="franchiseIndex"
            v-model="activeCompany"
            :loading="loadingCompanies"
            :headers="companiesHeaders"
            :items="companyDataTable"
            :footer-props="footerProps"
          >
            <template v-slot:top>
              <v-row no-gutters v-if="selectedCompany.level_id === 1" class="flex-column">
                <v-card-title class="text-body-1 pa-0"
                  >Desempeño General de la Red

                  <v-chip small class="ma-2">
                    Progreso
                    <v-chip small class="primary darken-4 p-2" text-color="white">
                      {{
                        selectedCompany.children_learning_progress
                          ? `${Math.ceil(selectedCompany.children_learning_progress)}%`
                          : '0%'
                      }}
                    </v-chip>
                  </v-chip>
                </v-card-title>
                <v-card-title class="text-body-1 pa-0"
                  >Desempeño Interno - {{ selectedCompany.name }}

                  <v-chip small class="ma-2">
                    Progreso
                    <v-chip small class="primary darken-4 p-2" text-color="white">
                      {{
                        selectedCompany.learning_progress
                          ? `${Math.ceil(selectedCompany.learning_progress)}%`
                          : '0%'
                      }}
                    </v-chip>
                  </v-chip>
                </v-card-title>
              </v-row>
              <v-row no-gutters v-if="selectedCompany.level_id === 2" class="flex-column">
                <v-card-title class="text-body-1 pa-0"
                  >Desempeño Interno - {{ selectedCompany.name }}

                  <v-chip small class="ma-2">
                    Progreso
                    <v-chip small class="primary darken-4 p-2" text-color="white">
                      {{
                        selectedCompany.learning_progress
                          ? `${Math.ceil(selectedCompany.learning_progress)}%`
                          : '0%'
                      }}
                    </v-chip>
                  </v-chip>
                </v-card-title>
                <v-card-title class="text-body-1 pa-0"
                  >Desempeño General de la Master

                  <v-chip small class="ma-2">
                    Progreso
                    <v-chip small class="primary darken-4 p-2" text-color="white">
                      {{
                        selectedCompany.children_learning_progress
                          ? `${Math.ceil(selectedCompany.children_learning_progress)}%`
                          : '0%'
                      }}
                    </v-chip>
                  </v-chip>
                </v-card-title>
              </v-row>
              <v-row no-gutters v-if="selectedCompany.level_id === 3" class="flex-column">
                <v-card-title class="text-body-1 pa-0"
                  >Desempeño Interno -
                  {{ selectedCompany.name }}

                  <v-chip small class="ma-2">
                    Progreso
                    <v-chip small class="primary darken-4 p-2" text-color="white">
                      {{
                        selectedCompany.learning_progress
                          ? `${Math.ceil(selectedCompany.learning_progress)}%`
                          : '0%'
                      }}
                    </v-chip>
                  </v-chip>
                </v-card-title>
                <!-- <v-card-title class="text-body-1 pa-0"
                  >Desempeño General de la Master

                  <v-chip small class="ma-2">
                    Progreso
                    <v-chip small class="primary darken-4 p-2" text-color="white">
                      {{
                        company.children.find(
                          (item) => item.id === selectedCompany.company_id
                        ).children_learning_progress
                          ? `${
                              company.children.find(
                                (item) => item.id === selectedCompany.company_id
                              ).children_learning_progress
                            }%`
                          : '0%'
                      }}
                    </v-chip>
                  </v-chip>
                </v-card-title> -->
              </v-row>
            </template>
            <template v-slot:[`header.name`]="{ header }">
              {{ selectedCompany.level_id === 3 ? 'Area Funcional' : header.text }}
            </template>
            <!-- <template v-slot:[`header.learning_progress`]="{ header }">
              {{ header.text }}
              <v-chip
                outlined
                x-small
                class="px-2"
                color="primary "
                v-if="selectedCompany.children && selectedCompany.children.length > 0"
              >
                Promedio:
                {{
                  selectedCompany.children_learning_progress
                    ? `${selectedCompany.children_learning_progress}%`
                    : "0%"
                }}
              </v-chip>
            </template> -->

            <template v-slot:[`item.learning_progress`]="{ item }">
              {{
                item.learning_progress ? `${Math.ceil(item.learning_progress)}%` : '0%'
              }}
            </template>
            <!-- <template v-slot:footer>
              <div class="text-center py-2">
                <v-pagination
                  @input="pagination($event)"
                  color="primary"
                  v-model="page"
                  :length="pages"
                />
              </div>
            </template> -->
          </v-data-table>
        </v-col>
      </v-row>
      <!-- / COMPANIES TREEVIEW -->
      <!-- FUNCTIONAL AREAS SECTION -->
      <v-card class="mt-4">
        <v-toolbar height="auto" dense color="primary" dark flat class="mx-4 py-2">
          <v-toolbar-title class="text-body-1" title="Capacitación y desempeño por área">
            Capacitación y desempeño por área
          </v-toolbar-title>
          <v-spacer />
          <v-chip
            small
            v-if="selectedCompany.level_id === 1"
            color="grey lighten-4"
            text-color="primary"
            class="pa-2"
            >Central
          </v-chip>
          <v-chip
            small
            v-if="selectedCompany.level_id === 2"
            color="grey lighten-4"
            text-color="primary"
            class="pa-2"
            >Master</v-chip
          >
          <span class="caption text-center"> {{ selectedCompany.name }}</span>
        </v-toolbar>

        <v-row class="mx-0" v-if="loadingAreas">
          <v-col cols="12" md="4">
            <v-skeleton-loader
              type="list-item"
              v-for="(i, index) in 3"
              :key="index"
              class="d-flex align-center px-4"
            >
              <v-skeleton-loader type="avatar" class="skeleton__avatar--x-mall mr-1" />
              <v-skeleton-loader type="avatar" />
              <v-skeleton-loader type="table-cell" class="ml-2" />
            </v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="8">
            <v-skeleton-loader type="image" style="width: 100%" />
          </v-col>
        </v-row>
        <v-row class="mx-0" v-else-if="!noAreasData">
          <v-col cols="12" md="4">
            <v-treeview color="primary" dense :items="areas" item-key="id" open-on-click>
              <template v-slot:prepend="{ item }">
                <v-icon class="float-left" v-if="item.model === 'functional_area'">
                  mdi-sitemap
                </v-icon>
                <v-icon class="float-left" v-if="item.model === 'position'">
                  mdi-account-group
                </v-icon>
              </template>
              <template v-slot:label="{ item }">
                <div class="label-container" @click="openAreaTreeview(item)">
                  <span class="label">
                    {{ item.name }}
                  </span>
                </div>
              </template>
            </v-treeview>
          </v-col>
          <!-- CODE FOR FUNCTIONAL_AREA MODEL -->
          <v-col cols="12" md="8" v-if="selectedArea.model === 'functional_area'">
            <v-scroll-y-transition mode="out-in">
              <v-card flat class="">
                <v-card-title class="pa-0 text-h6 primary--text font-weight-light">
                  <v-icon class="mr-1">mdi-sitemap</v-icon> Área funcional -
                  {{ selectedArea.name }}
                  <v-chip small class="ma-2">
                    Progreso
                    <v-chip small class="primary darken-4 p-2" text-color="white">
                      {{
                        selectedArea.learning_progress
                          ? `${Math.ceil(selectedArea.learning_progress)}%`
                          : '0%'
                      }}
                    </v-chip>
                  </v-chip>
                </v-card-title>
                <v-divider class="my-2" />
                <v-data-table
                  dense
                  :headers="areasHeaders"
                  :items="selectedArea.children"
                  class="mt-4"
                  :footer-props="footerProps"
                >
                  <template v-slot:top>
                    <v-row no-gutters align="center">
                      <v-card-title class="text-body-1 pa-0"
                        >Desempeño General</v-card-title
                      >
                    </v-row>
                  </template>
                  <!-- <template v-slot:[`header.learning_progress`]="{ header }">
                    {{ header.text
                    }}<v-chip
                      outlined
                      x-small
                      class="px-2"
                      color="primary"
                      v-if="selectedArea.children.length > 0"
                      >Promedio:
                      {{
                        selectedArea.children_learning_progress
                          ? `${selectedArea.children_learning_progress}%`
                          : "0%"
                      }}
                    </v-chip>
                  </template> -->
                  <template v-slot:[`item.learning_progress`]="{ item }">
                    {{
                      item.learning_progress
                        ? `${Math.ceil(item.learning_progress)}%`
                        : '0%'
                    }}
                  </template>
                  <!-- <template v-slot:footer>
                  <div class="text-center py-2">
                    <v-pagination
                      @input="pagination($event)"
                      color="primary"
                      v-model="page"
                      :length="pages"
                    />
                  </div>
                </template> -->
                </v-data-table>
              </v-card>
            </v-scroll-y-transition>
          </v-col>
          <!-- CODE FOR POSITION MODEL -->
          <v-col cols="12" md="8" v-else>
            <v-scroll-y-transition mode="out-in">
              <v-card flat class="">
                <v-card-title class="pa-0 text-h6 primary--text font-weight-light">
                  <v-icon class="mr-1">mdi-account-group</v-icon>
                  Cargo - {{ selectedArea.name }}
                  <v-chip small class="ma-2">
                    Progreso
                    <v-chip small class="primary darken-4 p-2" text-color="white">
                      {{
                        selectedArea.learning_progress
                          ? `${Math.ceil(selectedArea.learning_progress)}%`
                          : '0%'
                      }}
                    </v-chip>
                  </v-chip>
                </v-card-title>
                <v-divider class="my-2" />
                <v-chip small>
                  Mínimo
                  <v-chip small left class="primary darken-4 p-2" text-color="white">
                    {{ Math.ceil(selectedArea.learning_stats.min_progress) }}%
                  </v-chip>
                </v-chip>

                <v-chip small>
                  Máximo
                  <v-chip small left class="primary darken-4 p-2" text-color="white">
                    {{ Math.ceil(selectedArea.learning_stats.max_progress) }}%
                  </v-chip>
                </v-chip>

                <v-chip small>
                  Usuarios
                  <v-chip small left class="primary darken-4 p-2" text-color="white">
                    {{ selectedArea.learning_stats.users_count }}
                  </v-chip>
                </v-chip>
                <v-chip small>
                  Cursos
                  <v-chip small left class="primary darken-4 p-2" text-color="white">
                    {{ selectedArea.learning_stats.courses }}
                  </v-chip>
                </v-chip>

                <v-chip small>
                  Lecciones
                  <v-chip small left class="primary darken-4 p-2" text-color="white">
                    {{ selectedArea.learning_stats.lessons }}
                  </v-chip>
                </v-chip>
                <v-row class="mx-0 mt-4">
                  <v-col cols="12" lg="6">
                    <v-data-table
                      dense
                      :headers="positionsHeaders"
                      :items="selectedArea.users_by_company"
                      :footer-props="footerProps"
                    >
                      <template v-slot:top>
                        <v-row no-gutters align="center">
                          <v-card-title class="text-body-1 pa-0"
                            >Desempeño General</v-card-title
                          >
                        </v-row>
                      </template>
                      <template v-slot:[`item.name`]="{ item }">
                        {{
                          item.name && item.lastname
                            ? `${item.name} ${item.lastname}`
                            : item.name
                        }}
                      </template>
                      <!-- <template v-slot:[`header.learning_progress`]="{ header }">
                        {{ header.text
                        }}<v-chip
                          outlined
                          x-small
                          class="px-2"
                          color="primary "
                          v-if="selectedArea.users_by_company.length > 0"
                        >
                          Promedio:
                          {{
                            selectedArea.learning_progress
                              ? `${selectedArea.learning_progress}%`
                              : "0%"
                          }}
                        </v-chip>
                      </template> -->
                      <template v-slot:[`item.learning_progress`]="{ item }">
                        {{
                          item.learning_progress
                            ? `${Math.ceil(item.learning_progress)}%`
                            : '0%'
                        }}
                      </template>
                      <!-- <template v-slot:footer>
                      <div class="text-center py-2">
                        <v-pagination
                          @input="pagination($event)"
                          color="primary"
                          v-model="page"
                          :length="pages"
                        />
                      </div>
                    </template> -->
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-card flat class="mx-auto">
                      <v-row no-gutters align="center">
                        <v-card-title class="text-body-1 pa-0"
                          >Plan de capacitacion</v-card-title
                        >

                        <v-spacer />
                        <v-btn icon @click="handleCapacitationDialog">
                          <v-icon color="primary">mdi-plus</v-icon>
                        </v-btn>
                      </v-row>
                      <v-skeleton-loader
                        class="px-6 py-3 d-flex align-center"
                        height="48"
                        v-if="loadingPositionCourses"
                        elevation="2"
                      >
                        <v-skeleton-loader type="chip" width="200" />
                        <v-spacer />
                        <v-skeleton-loader
                          type="avatar"
                          class="skeleton__avatar--x-mall"
                        />
                      </v-skeleton-loader>
                      <v-card-text v-else-if="positionCourses.length === 0">
                        No hay lecciones asignadas. Crea un plan de capacitación
                      </v-card-text>

                      <v-expansion-panels
                        accordion
                        focusable
                        v-else
                        :key="positionCoursesIndex"
                      >
                        <v-expansion-panel
                          v-for="(course, index) in positionCourses"
                          :key="index"
                        >
                          <v-expansion-panel-header expand-icon="mdi-menu-down">{{
                            course[0]
                          }}</v-expansion-panel-header>

                          <v-expansion-panel-content
                            v-for="lesson in course[1]"
                            :key="lesson.id"
                          >
                            {{ lesson.name }}
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-scroll-y-transition>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          align="center"
          justify="center"
          class="py-16"
          v-else-if="noAreasData"
        >
          La empresa selecionada no posee áreas funcionales.
        </v-row>
      </v-card>
      <!-- / FUNCTIONAL AREAS SECTION -->
    </v-card>
    <v-dialog v-model="dialog" width="70%">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          Plan de capacitacion para: {{ selectedArea.name }}
        </v-card-title>

        <v-card-text>
          <br />

          <v-row>
            <v-expansion-panels
              focusable
              accordion
              flat
              hover
              v-if="companyCourses.length > 0 && pCourses.lessons"
            >
              <v-expansion-panel v-for="(course, i) in companyCourses" :key="i">
                <v-expansion-panel-header>{{ course.name }} </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="px-2">
                    <v-col
                      cols="12"
                      md="6"
                      v-for="(lesson, id) in course.lessons"
                      :key="id"
                    >
                      <v-checkbox
                        :label="lesson.name"
                        v-model="assignedCourses"
                        :value="lesson.id"
                        dense
                        color="primary"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="handlePositionCourses"> Actualizar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { decryptData } from '@/utils/encryption'
  import { toLocaleDateString } from '@/utils/helpers'
  export default {
    data: function () {
      return {
        config: {},

        valid: true,

        toDay: new Date(),

        franchiseIndex: 0,
        evaluationIndex: 0,
        footerProps: {
          options: {
            itemsPerPage: 10,
          },
          itemsPerPageOptions: [5, 10, 15],
          itemsPerPageText: '',
        },
        noAreasData: false,
        // page: null,
        // pages: null,
        dialog: false,
        loading: false,
        companies: [],
        loadingCompanies: false,
        activeCompany: [],
        selectedCompany: null,
        companyDataTable: [],
        companyCourses: [],
        areas: [],
        loadingAreas: false,
        selectedArea: {},
        activeArea: [],
        pCourses: {},
        positionCourses: [],
        positionCoursesIndex: 0,
        loadingPositionCourses: false,
        assignedCourses: [],
        loadingStats: false,
        list: [
          { name: 'Curso 1', id: 0 },
          { name: 'Curso 2', id: 1 },
          { name: 'Curso 3', id: 2 },
        ],
        itemsList: [
          {
            title: 'Curso 1',
            action: 'mdi-school',
            items: [
              { title: 'Leccion 1' },
              { title: 'Leccion 2' },
              { title: 'Leccion 3' },
              { title: 'Leccion 4' },
              { title: 'Leccion 5' },
            ],
          },
          {
            title: 'Curso 2',
            action: 'mdi-school',
            items: [
              { title: 'Leccion 1' },
              { title: 'Leccion 2' },
              { title: 'Leccion 3' },
            ],
          },
        ],
        companiesHeaders: [
          {
            text: 'Empresas',
            align: 'start',

            value: 'name',
          },
          {
            text: 'Progreso',
            value: 'learning_progress',
            class: 'custom-progress',
          },
        ],

        areasHeaders: [
          {
            text: 'Cargo',
            align: 'start',

            value: 'name',
          },
          { text: 'Progreso', value: 'learning_progress', class: 'custom-progress' },
        ],
        positionsHeaders: [
          {
            text: 'Usuarios',
            align: 'start',
            value: 'name',
          },
          { text: 'Progreso', value: 'learning_progress', class: 'custom-progress' },
        ],
        attrs: {
          class: 'mb-6',
          boilerplate: true,
          elevation: 2,
        },
        navigationElements: [
          {
            text: 'Talento Humano',
            disabled: false,
            href: '/administrative/human-talent/admin-panel',
          },
          {
            text: 'Desempeño de la red',
            disabled: true,
            href: '',
          },
        ],
      }
    },
    created() {
      this.selectedCompany = this.company

      this.getCompanies()
      this.getFunctionalAreas()
      this.getCompanyCourses()
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
    },
    watch: {
      selectedCompany(newValue, oldValue) {
        if (newValue && oldValue && newValue.level_id !== oldValue.level_id) {
          this.getCompanyCourses()
        }
        if (newValue && oldValue && newValue.id !== oldValue.id) {
          this.getFunctionalAreas()
        }
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,
      handleCapacitationDialog() {
        this.dialog = true
      },
      timelineDateString(date, locales) {
        try {
          return new Date(date).toLocaleDateString(locales, {
            day: 'numeric',
            month: 'long',
          })
        } catch (error) {
          console.warn(error)
          return 'Invalid data'
        }
      },
      // async pagination(page) {
      //   this.page = page;
      //   await this.getRequests();
      // },
      openCompanyTree(item) {
        this.selectedCompany = item
        if (item.level_id < 3) {
          this.companyDataTable = item.children
        }
        if (item.level_id === 3) {
          this.companyDataTable = []
        }
      },
      openAreaTreeview(item) {
        this.selectedArea = item
        this.assignedCourses = []
        if (item.model === 'position') {
          this.getPositionCourses()
        }
      },

      evaluationName({ evaluation, id }) {
        if (!evaluation) return ''
        return `${evaluation.name} #${id}`
      },
      fullName({ name, lastname }) {
        if (!name) return ''
        return `${name} ${lastname}`
      },
      collaboratorName({ name, lastname, email }) {
        if (name && name !== 'Todos') {
          if (lastname && email) {
            return `${name} ${lastname} | ${email}`
          } else {
            return name
          }
        } else {
          return name
        }
      },
      async getCompanies() {
        this.loadingCompanies = true
        this.$axios
          .get(
            'companies/' +
              this.selectedCompany.id +
              '?with[]=children.children.children&appends[]=learning_progress&with[]=countries'
          )
          .then((response) => {
            this.companies = [response.data]
            this.selectedCompany = this.companies[0]
            this.companyDataTable = this.selectedCompany.children
            this.loadingCompanies = false
          })
      },
      async getFunctionalAreas() {
        this.loadingAreas = true
        const appendString = '&appends[]=users_by_company&appends[]=learning_progress'
        this.$axios
          .get(
            'functional-areas?limit=30&type_id=' +
              this.selectedCompany.type_id +
              '&company_id=' +
              this.selectedCompany.id +
              appendString
          )
          .then((response) => {
            if (response.data.length > 0) {
              this.areas = response.data
              this.selectedArea = this.areas[0]
              this.noAreasData = false
              if (this.selectedCompany.level_id === 3) {
                this.companyDataTable = this.areas
                this.franchiseIndex++
              }
            } else {
              this.noAreasData = true
            }
            this.loadingAreas = false
          })
      },
      async getStats() {
        this.loadingStats = true
        const companyId = parseInt(this.selectedCompany.id)
        this.$axios.get('request-stats?company_id=' + companyId).then((response) => {
          this.loadingStats = false
        })
      },
      async getCompanyCourses() {
        this.$axios
          .get(
            'courses?limit=100&with[]=lessons&level_id=' + this.selectedCompany.level_id
          )
          .then((response) => {
            this.companyCourses = response.data
          })
      },
      async getPositionCourses() {
        this.loadingPositionCourses = true
        this.$axios
          .get('positions/' + this.selectedArea.id + '?with[]=lessons.course')
          .then((response) => {
            this.pCourses = response.data

            const groupByCourse = this.pCourses.lessons.reduce((group, lesson) => {
              // const { course_name } = lesson;
              group[lesson.course.name] = group[lesson.course.name] ?? []
              group[lesson.course.name].push(lesson)
              return group
            }, {})
            this.positionCourses = Object.entries(groupByCourse)
            this.loadingPositionCourses = false
            this.handleCheckbox()
            this.positionCoursesIndex++
          })
      },
      handleCheckbox() {
        this.pCourses.lessons.forEach((lesson, index) => {
          this.assignedCourses[index] = lesson.id
        })
      },
      async handlePositionCourses() {
        this.$axios.patch('positions/' + this.selectedArea.id, {
          lessons: this.assignedCourses,
        })
        this.dialog = false

        this.getPositionCourses()
      },
    },
  }
</script>
<style lang="scss">
  .training-performance {
    .v-chip {
      min-width: fit-content;
      margin: 4px;
      padding: 0 0 0 8px;
      .v-chip {
        padding: 0 8px;
        margin-right: 0;
      }
    }
    .v-input--radio-group__input {
      flex-direction: row;
      flex-wrap: wrap;
    }
    .custom-progress {
      display: flex;
      align-items: center;
      width: 100%;
      .v-chip {
        order: 3;
        margin-left: auto;
      }
    }
    .label-container {
      height: 40px;
      display: flex;
      align-items: center;
      .label {
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .v-treeview {
      max-height: 500px;
      overflow-y: auto;
    }
    .v-skeleton-loader {
      .v-skeleton-loader__chip {
        height: 24px;
        width: 55px;
      }
      .v-skeleton-loader__avatar {
        height: 25px;
        width: 25px;
        margin: 4px;
      }
      .skeleton__avatar--x-mall > .v-skeleton-loader__avatar {
        width: 15px;
        height: 15px;
      }
    }
  }
</style>
